.container__main.full {
    width: 90vw;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    position: absolute;
    .main__section.media {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        outline: none;
        &.disable-hover:hover {
            outline: none;
            transform: scale(1);
            box-shadow: none;
        }
        .section__image {
            width: 40%;
            height: auto;
            position: relative;
            margin: 20px;
            background: grey;
            border-radius: 5px;
            pointer-events: normal;
            transition: 100ms ease all;
            filter: brightness(95%);
            &:hover {
                filter: brightness(100%);
                z-index: 98;
                transform: scale(1.05);
                box-shadow: 0 10px 20px 1px rgba(0,0,0,0.1);
            }
        }
    }
}
@media screen and (max-width: 890px) {
    .container__main.full {
        .main__section.media {
            flex-direction: column;
            .section__image {
                width: 80%;
                min-width: 80%;
            }
        }
    }
}