@import "./vars";

@font-face {
    font-family: poppy;
    font-style: normal;
    src:
        local($before-load_font),
        local($before-load_font),
        url("../../font/Poppins-Regular.ttf") format("ttf");
    font-display: swap;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    font-family: poppy;
    a {
        color: $green-4;
    }
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: $green-3;
    }
}

@import "./loading";
@import "./loadingsub";

.disabled {
    visibility: hidden;
    display: none;
    pointer-events: none;
    position: absolute;
    outline: none;
    border: none;
}

.message {
    font-size: 2em;
    font-weight: bold;
    padding: 20px;
    background: $green-3-transparent;
    border-radius: 10px;
    outline: 1px solid $green-3;
    span {
        font-weight: normal;
        display: block;
        font-size: 0.5em;
    }
}

.no-outline {
    outline: none !important;
}
.no-border {
    border: none !important;
}

.pointer-events_none {
    pointer-events: none;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: $header-height;
    left: 0;
    top: 0;
    background: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 99;

    .header__logo {
        width: 75px;
        height: 75px;
        left: 5vw;
        position: absolute;
        cursor: pointer;
        outline: none;
        border: none;
        z-index: 99;
        background: none;
        background-image: url("../../assets/images/logos/ledyardseniorhousinglogo.png");
        background-position: center;
        background-size: cover;
    }
    .header__nav {
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        list-style: none;

        .nav__link {
            display: flex;
            align-items: center;
            justify-content: center;

            .link__a {
                color: rgb(10, 10, 10);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2em;
                text-decoration: none;
                padding: 0 50px;
                height: $header-height;
                transition: 100ms ease all;
                &:hover {
                    color: $green-2;
                    background: white;
                    filter: brightness(95%);
                }
            }
        }
    }
}
@media screen and (min-width: 471px) {
    .header {
        .header__nav {
            &.header__nav_1 {
                display: flex;
            }
            &.header__nav_2 {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 470px) {
    .header {
        .header__nav {
            &.header__nav_1 {
                display: none;
            }
            &.header__nav_2 {
                display: flex;
            }
        }
    }
}
.container {
    display: flex;
    align-items: top;
    justify-content: space-around;
    padding: 2.5vh;
    flex-direction: row;
    width: 100vw;
    height: calc(100vh - $header-height);
    left: 0;
    background: rgb(255, 255, 255);
    top: $header-height;
    position: absolute;

    .container__main {
        display: flex;
        align-items: center;
        justify-content: top;
        flex-direction: column;
        width: 60%;
        margin-right: 5vw;
        height: auto;
        position: relative;
        .main__section {
            width: 100%;
            height: auto;
            padding: 0 10vw 10px 5vw;
            border-bottom: 1px solid rgb(220, 220, 220);
            margin-bottom: 10px;
            border: none;
            outline: 2px solid rgba(120, 243, 67, 0);
            background: rgb(255, 255, 255);
            transition: 100ms ease all;
            position: relative;
            &:hover {
                transform: scale(1.05);
                z-index: 98;
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
                outline: 2px solid $green-3;
            }
            &.disable-hover:hover {
                outline: none;
                transform: scale(1);
                box-shadow: none;
            }
            &:nth-child(1) {
                margin-top: 50px;
            }
            .map {
                width: 100px;
                height: 200px;
                position: absolute;
            }
            &:nth-child(1) {
                .section__title {
                    position: relative;
                    &::after {
                        $height: 2px;

                        content: "";
                        width: 100%;
                        height: $height;
                        left: 0;
                        bottom: calc($height * -1 - 10px);
                        background: rgb(220, 220, 220);
                        position: absolute;
                    }
                }
            }
            .section__list {
                list-style: circle;
                color: rgb(50, 50, 50);
            }
            .section__image.pdf {
                width: 100%;
                background: grey;
                margin: 0;
                transition: 100ms ease all;
                z-index: 98;
                cursor: pointer;
                &:hover {
                    z-index: 10000;
                    transform: scale(1.05);
                    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
                }
            }
            .section__text {
                a {
                    padding: 0 10px 0 10px;
                }
            }
            &.pdf-viewer {
                pointer-events: all;
                display: flex;
                padding: 0;
                align-items: center;
                justify-content: top;
                flex-direction: column;
                position: relative;
            }
        }
    }
}
.seperator {
    padding: 20px;
}

@import "../pages/home/home.scss";
@import "../pages/media/media.scss";

@media screen and (max-width: 470px) {
    .header {
        .header__nav_2 {
            .nav__link {
                .link__a {
                    font-size: 3em;
                    &:hover {
                        color: $green-2;
                    }
                    span {
                        font-size: 20px;
                        font-family: $default-font;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 384px) {
    .header {
        .header__nav_2 {
            .nav__link {
                .link__a {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
