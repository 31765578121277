:root {
    --hue: 102;
	--bg: hsl(var(--hue),88%,61%);
	--fg: hsl(var(--hue),10%,10%);
}
.container__loading {
    display: flex;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    position: absolute;
	font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}
.pl {
    position: relative;
	display: block;
	width: 6.25em;
	height: 6.25em;
}
.pl__ring, .pl__ball {
	animation: ring 2s ease-out infinite;
}
.pl__ball {
	animation-name: ball;
}

/* Animation */
@keyframes ring {
	from {
		stroke-dasharray: 0 257 0 0 1 0 0 258;
	}
	25% {
		stroke-dasharray: 0 0 0 0 257 0 258 0;
	}
	50%, to {
		stroke-dasharray: 0 0 0 0 0 515 0 0;
	}
}
@keyframes ball {
	from, 50% {
		animation-timing-function: ease-in;
		stroke-dashoffset: 1;
	}
	64% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -109;
	}
	78% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -145;
	}
	92% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -157;
	}
	57%, 71%, 85%, 99%, to {
		animation-timing-function: ease-out;
		stroke-dashoffset: -163;
	}
}