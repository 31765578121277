.container {
    .container__sidebar {
        display: flex;
        flex-direction: column;
        width: 30%;
        top: 5vh;
        height: fit-content;
        padding: 20px;
        margin-bottom: 10px;
        background: rgb(255, 255, 255);
        outline: 2px solid $green-3;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        .sidebar__section {
            border-bottom: 1px solid rgb(220, 220, 220);
            padding: 10px 10px 30px 10px;
            h2.section__title {
                font-size: 1.2em;
                span {
                    color: $green-4;
                }
            }
        }
    }
    .container__main {
        .main__section.e-h-o-l {
            opacity: 0;
        }
    }
}
@media screen and (min-width: 801px) {
    #sections {
        visibility: visible;
        display: block;
        pointer-events: all;
        position: relative;
    }
    .container {
        justify-content: center;
        align-items: top;
        .container__sidebar {
            order: 2;
        }
    }
}
@media screen and (max-width: 800px) {
    #sections {
        visibility: hidden;
        display: none;
        pointer-events: none;
        position: absolute;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0;
        .container__sidebar {
            display: flex;
            flex-direction: row;
            width: 90%;
            height: auto;
            padding: 0 5vw;
            .sidebar__section {
                padding: 0 5vw 20px 20px;
                border-right: 2px solid $green-3;
                &:nth-child(3) {
                    border: none;
                }
            }
        }
        .container__main {
            display: flex;
            align-items: center;
            justify-content: top;
            padding: 0;
            width: 85%;
            top: 5vh;
            margin-right: 0;
            position: relative;
            .main__section {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 645px) {
    #sections {
        visibility: visible;
        display: block;
        pointer-events: all;
        position: relative;
    }
    .container {
        .container__sidebar {
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 0;
            .sidebar__section {
                border: none;
                &:nth-child(1) {
                    margin-top: 10px;
                    border-right: 1px solid rgb(220, 220, 220);
                }
                h2.section__title {
                    font-weight: 500;
                    font-size: 1em;
                }
            }
        }
    }
}
@media screen and (max-width: 506px) {
    .container {
        .container__sidebar {
            justify-content: left;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 0;
            .sidebar__section {
                border: none !important;
            }
        }
    }
}
